import { Divider, Space, Typography } from 'antd';
import React from 'react';

const { Title, Paragraph, Text } = Typography;

function TermsOfService() {
  return (
    <div style={{ padding: '2rem', maxWidth: '800px', margin: '0 auto' }}> 
        <Title>Terms of Service for Email Labeler</Title>

        <Paragraph>
            <Text strong>Effective Date:</Text> September 4th, 2024 GMT +3
        </Paragraph>

        <Divider />

        <Title level={2}>Acceptance of Terms</Title>
        <Paragraph>
            By using Email Labeler, you agree to be bound by these Terms of Service. If you do not agree to these terms, you may not use the service.
        </Paragraph>

        <Divider />

        <Title level={2}>Use of API Keys</Title>
        <Paragraph>
            You are solely responsible for the API keys you use with Email Labeler. You may only use your own API keys or those you are explicitly authorized to use. Any unauthorized use of API keys is strictly prohibited.
        </Paragraph>

        <Divider />

        <Title level={2}>Prohibited Activities</Title>
        <Paragraph>
            You agree not to engage in any of the following activities:
        </Paragraph>
        <ul style={{ listStyleType: 'disc', paddingLeft: '1.5rem' }}>
            <li>Attempting to reverse engineer, decompile, disassemble, or otherwise derive the source code of Email Labeler.</li>
            <li>Engaging in any activity that could disrupt, impair, or interfere with the proper functioning of Email Labeler, including but not limited to hacking, denial-of-service attacks, or other malicious actions.</li>
            <li>Abusing the email labeling service in any way, including but not limited to excessive usage or attempts to bypass usage limitations.</li>
            <li>Using Email Labeler for any illegal, harmful, or unethical purposes.</li>
        </ul>

        <Divider />

        <Title level={2}>Disclaimer of Warranties</Title>
        <Paragraph>
            Email Labeler is provided "as is" without any warranties, express or implied. We do not guarantee that the service will be uninterrupted, error-free, or secure.
        </Paragraph>

        <Divider />

        <Title level={2}>Limitation of Liability</Title>
        <Paragraph>
            To the fullest extent permitted by law, we shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages arising out of or in connection with your use of Email Labeler.
        </Paragraph>

        <Divider />

        <Title level={2}>Data Handling</Title>
        <Paragraph>
            Email Labeler does not store or save any of your emails. We only process your emails temporarily for the purpose of labeling them.
        </Paragraph>

        <Divider />

        <Title level={2}>Waiver of Legal Action</Title>
        <Paragraph>
            You agree to waive any right to bring a class action lawsuit or participate in any class action against us related to your use of Email Labeler.
        </Paragraph>

        <Divider />

        <Title level={2}>Governing Law and Jurisdiction</Title>
        <Paragraph>
            These Terms of Service shall be governed by and construed in accordance with the laws of Israel. Any disputes arising out of or in connection with these terms shall be subject to the exclusive jurisdiction of the courts of Israel. You further agree that any such disputes shall be resolved through mediation in Israel before any legal action is initiated.
        </Paragraph>

        <Divider />

        <Title level={2}>Changes to these Terms of Service</Title>
        <Paragraph>
            We reserve the right to modify or update these Terms of Service at any time. Any changes will be effective immediately upon posting the revised Terms of Service.
        </Paragraph>

        <Space direction="vertical" size="large"> 
            {/* ... You can add more sections or content here if needed ... */}
        </Space>
    </div>
  );
}

export default TermsOfService;