import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css'; // Import your CSS for styling (if applicable)
import EmailLabeling from './EmailLabeling';
import GmailAuth from './GmailAuth';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<GmailAuth />} />
        <Route path="/email-labeling" element={<EmailLabeling />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        {/* <Route path="/openai-key-guide" element={<OpenAIKeyGuide />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;