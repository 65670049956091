import { Divider, Space, Typography } from 'antd';
import React from 'react';

const { Title, Paragraph, Text } = Typography;

function PrivacyPolicy() {
    return (
        <div style={{ padding: '2rem', maxWidth: '800px', margin: '0 auto' }}> {/* Add padding and center the content */}
            <Title>Privacy Policy for Email Labeler</Title>

            <Paragraph>
                <Text strong>Effective Date:</Text> September 4th, 2024 GMT +3
            </Paragraph>

            <Divider />

            <Title level={2}>Introduction</Title>
            <Paragraph>
                Email Labeler ("we," "us," or "our") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our email labeling service integrated with Gmail.
            </Paragraph>

            <Divider />

            <Title level={2}>Information We Collect</Title>
            <ul style={{ listStyleType: 'disc', paddingLeft: '1.5rem' }}> {/* Add styling to the list */}
                <li>
                    <Paragraph>
                        <Text strong>Personal Information:</Text> We collect your email address when you authenticate with Gmail. This information is necessary to identify your account and provide the email labeling service.
                    </Paragraph>
                </li>
                <li>
                    <Paragraph>
                        <Text strong>OpenAI API Key and Google Tokens:</Text> We store your OpenAI API key and Google tokens in an encrypted format. This is required to access the OpenAI API for labeling your emails and interact with your Gmail account on your behalf.
                    </Paragraph>
                </li>
                <li>
                    <Paragraph>
                        <Text strong>Cookies:</Text> We use cookies to store your JWT (JSON Web Token). This token is used for authentication and authorization purposes to ensure secure access to your account and data.
                    </Paragraph>
                </li>
            </ul>

            <Divider />

            <Title level={2}>Account Deletion</Title> {/* Add the Account Deletion section */}
            <Paragraph>
                You can request the deletion of your account and associated data from our systems. To do so, please send an email to <a href="mailto:davidawarshawsky@gmail.com?subject=DELETE MY ACCOUNT EMAIL LABELER">davidawarshawsky@gmail.com</a> with the subject line "DELETE MY ACCOUNT EMAIL LABELER." We will process your request as soon as possible. Please note that a delete account button will be added to the application in a future update.
            </Paragraph>

            <Space direction="vertical" size="large"> {/* Add spacing for better readability */}
                {/* ... rest of your Privacy Policy content ... */}
            </Space>
            <Divider />

            <Title level={2}>Changes to this Privacy Policy</Title> 
            <Paragraph>
                We reserve the right to modify or update this Privacy Policy at any time. Any changes will be effective immediately upon posting
                the revised Privacy Policy. We encourage you to review this Privacy Policy periodically for any updates.
            </Paragraph>

        </div>
    );
}

export default PrivacyPolicy;